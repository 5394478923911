<template>
  <div class="price">
    <!-- Loading -->
    <!-- <div class="loading"><div><img src="img/favicon.png"><div></div></div></div> -->
    <!-- Main -->
    <section class="head">
      <div class="bg"></div>
      <span> {{ firestoreInfo?.shopName }} </span>
    </section>
    <!-- Footer -->
    <section v-if="filteredPaymentList.length > 1" class="foot">
      <button
        @click="goToPayment"
        :class="{ disabled: !filteredPaymentList.length || !selectedPaymentOrder?.payUrl}"
      >
        <span> {{ $t("Confirm the amount") }} </span>
      </button>
    </section>
    <section class="main">
      <span class="title"> {{ title }}</span>
      <template v-if="filteredPaymentList.length > 1">
        <div class="order__ckeck-list">
          <div
            v-for="payment in filteredPaymentList"
            :key="payment.ddpayOrderId"
          >
            <PaymentOrder :payment="payment"></PaymentOrder>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="loader-animate">
          <div class="loader"></div>
          <div class="gif">
            <div><img src="@/assets/icon/waiting1.svg" /></div>
            <div><img src="@/assets/icon/waiting2.svg" /></div>
            <div><img src="@/assets/icon/waiting3.svg" /></div>
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";
// import LogoHeader from "@/components/LogoHeader.vue";
import { formatCurrency } from "@/utils/utils";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import fsdb from "@/store/modules/fsdb";
import { fetchFirestoreCustomToken } from "@/apis/fetchData";
import moment from "moment-timezone";
import PaymentOrder from "@/components/PaymentOrder.vue";

export default {
  name: "PaymentList",
  components: { PaymentOrder },
  async beforeRouteEnter(to, from, next) {
    // react to route changes...
    console.log("payment list terminalId:" + to.params.terminalId);
    if (to.params.terminalId) {
      next((vm) => {
        fetchFirestoreCustomToken(to.params.terminalId, {
          services: "fqr",
        })
          .then((res) => {
            console.log("fetchFirestoreCustomToken done", res);
            // vm.firestoreInfo = res.data;
            vm.$store.commit("paymentList/setFirestoreInfo", res.data);
            vm.$nextTick(vm.subscribeFirestore());
            next();
          })
          .catch((e) => {
            console.error("fetchFirestoreCustomToken failed", e);
            // vm.$notify({
            //   group: 'noti-popup',
            //   title: 'Error',
            //   text: 'Failed to get firestore token!'
            // })
            next({
              path: "/error",  
              query: { code: e.response?.data?.error?.code || null } 
            });
          });
      });
    } else {
      // 跳轉錯誤頁
      console.log("redirect to error page");
      // TM001 : Found no such terminal.
      next({
        path: "/error",  
        query: { code: 'TM001' } 
      });
    }
    // this.userData = await fetchUser(to.params.token)
  },
  data() {
    return {
      formatCurrency,
      moment,
      // firestoreInfo: null,
      unsub: null,
      filteredPaymentList: [],
      timer: null,
    };
  },
  watch: {
    "filteredPaymentList.length": {
      handler(newVal) {
        if (newVal && newVal === 1) {
          this.$store.commit('paymentList/setSelectedPaymentOrder', this.filteredPaymentList[0]);
          this.goToPayment();
          // this.goToPayment(this.filteredPaymentList[0].payUrl);
        }
      },
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      user: (state) => state.user,
    }),
    ...mapGetters({
      paymentList: "paymentList/getPaymentList",
      selectedPaymentOrder: "paymentList/getSelectedPaymentOrder",
      firestoreInfo: "paymentList/getFirestoreInfo",
    }),
    token() {
      return this.$route.params.token;
    },
    title() {
      if (this.filteredPaymentList.length > 1) {
        return this.$t("Select order to checkout");
      } else {
        return this.$t("Waiting for orders");
      }
    },
    // filteredPaymentList() {
    //   // FIRESTORE資料取得後，先篩選一遍expiration time
    //   const current = moment();
    //   const list = [];
    //   this.paymentList.forEach((payment) => {
    //     const expired = moment.unix(payment.expirationTs);
    //     console.log(current, expired, current.diff(expired, 'minutes'))
    //     if (expired.diff(current, 'minutes') > 1) {
    //       list.push(payment);
    //     }
    //   });
    //   return list;
    // },
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  mounted() {},
  methods: {
    getDummyData() {
      return [
        {
          createdAt: 1718783060,
          ddpayOrderId: "2406171347311A0RDV",
          merchantOrderId: "0000054321",
          serialNo: "33",
          orderInfo: {
            amount: 100,
            description: "測試店的訂單",
            products: [
              {
                id: "p001",
                name: "不可能這麼便宜的雞排",
                qty: 2,
                price: 55,
                amount: 110,
              },
            ],
            discounts: [
              {
                type: "FLATOFF",
                amount: 10,
                name: "整單折價券測試",
              },
            ],
            currencySettings: {
              style: "currency",
              currency: "PHP",
              trailingZeroDisplay: "stripIfInteger",
              maximumFractionDigits: 2,
            },
          },
          expirationTs: 1818783060,
          payUrl: "https://pay.ddc.ai/abcd",
        },
        {
          createdAt: 1718783060,
          ddpayOrderId: "2406171347311A0RDM",
          merchantOrderId: "0000054322",
          serialNo: "34",
          orderInfo: {
            amount: 50,
            description: "測試店的訂單",
            products: [
              {
                id: "p001",
                name: "不可能這麼便宜的雞排",
                qty: 2,
                price: 55,
                amount: 110,
              },
            ],
            discounts: [
              {
                type: "FLATOFF",
                amount: 10,
                name: "整單折價券測試",
              },
            ],
            currencySettings: {
              style: "currency",
              currency: "TWD",
              trailingZeroDisplay: "stripIfInteger",
              maximumFractionDigits: 2,
            },
          },
          expirationTs: 1818779164,
          payUrl:
            "http://localhost:8080/orders/2984083474ceb377503f54877d29378586184016b5467e07895a750e0056f0eb?openExternalBrowser=1",
        },
      ];
    },
    subscribeFirestore() {
      console.log('subscribeFirestore:' + this.firestoreInfo.customToken);
      const shopId = this.firestoreInfo.shopId;
      const terminalId = this.firestoreInfo.terminalId;
      console.log(`/shops/${shopId}/terminals/${terminalId}/queueingPayments`);
      if (this.unsub) {
        this.unsub();
      }
      const auth = getAuth();
      const self = this;
      signInWithCustomToken(auth, this.firestoreInfo.customToken)
        .then(() => {
          // Signed in..
          this.unsub = onSnapshot(
            collection(
              fsdb,
              `/shops/${shopId}/terminals/${terminalId}/queueingPayments`
            ),
            (querySnapshot) => {
              console.log("filestore querySnapshot", querySnapshot);
              let payments = [];
              querySnapshot.docs.forEach((doc) => {
                console.log("Current data: ", doc.data());
                payments.push(doc.data());
              });
              // only for test, should be remove
              // if (!payments.length) {
              //   payments = self.getDummyData();
              // }
              self.$store.commit("paymentList/setPaymentList", payments);
              self.checkExpiration();
            }
          );
          // this.checkExpiration();
        })
        .catch((error) => {
          console.log("signInWithCustomToken", error);
          // this.checkExpiration();
          this.$router.push("error");
        });
    },
    checkExpiration() {
      console.log("check expiration");
      if (this.timer) {
        clearTimeout(this.timer);
      }
      const self = this;
      this.timer = setTimeout(function () {
        const current = moment();
        self.filteredPaymentList = [];
        self.paymentList?.forEach((payment) => {
          const expired = moment.unix(payment.expirationTs);
          const isOrderDone = payment.state === 'done';
          // if (isOrderDone) {
          //   // console.log(`order ${payment?.merchantOrderId} has been paid`);
          // }
          if (!isOrderDone) { 
            if (expired && expired.diff(current, "minutes") > 1) {
              self.filteredPaymentList.push(payment);
            } else if (!expired) { // workaround，沒有給expirationTs的，固定顯示
              self.filteredPaymentList.push(payment);
            }
          }
        });
        self.checkExpiration();
      }, 1000);
    },
    goToPayment() {
      window.location.href = this.selectedPaymentOrder.payUrl;
      // let url =  new URL(this.selectedPaymentOrder.payUrl);
      // if (this.$route.query['x-api-authorization']) {
      //   let params = new URLSearchParams(url.search);
      //   params.append('x-api-authorization', this.$route.query['x-api-authorization']);
      //   url.search = params;
      // }
      //   window.location.href = url.toString();
    },
  },
};
</script>
